@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');
body {
	font-family: 'Montserrat', sans-serif;
}

/* .ant-form-vertical .ant-form-item-label > label {
  color: white;
} */

.ant-layout-sider-children {
	background-color: #24b871;
}

.ant-menu-root.ant-menu-vertical, .ant-menu-root.ant-menu-vertical-left, .ant-menu-root.ant-menu-vertical-right, .ant-menu-root.ant-menu-inline {
	background-color: #24b871;
	color: white;
	border-right: none;
}

.ant-layout-sider-light .ant-layout-sider-trigger {
	background-color: #24b871;
}

.ant-table-thead > tr > th {
  background: rgba(66, 153, 225, var(--bg-opacity));
  color: white;
}